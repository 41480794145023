<template>
    <v-app id="inspire">
        <slot/>
    </v-app>
</template>
<script setup>
</script>
<style>
.text-default {
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}
</style>
